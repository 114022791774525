import React, { FormEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  documentMetadata,
  transferService,
} from "../../../state/config/config.selector";
import {
  authorizationHeader,
  userToken,
} from "../../../state/user/user.selector";
import { cancelDashboardOption } from "../../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../../state/workspaces/workspaces.selector";
import { Localized } from "../../../strings";
import { Modal } from "../Modal";
import Styles from "./DownloadDialog.module.css";
import { Button, downloadBlob, TextInput } from "@hoylu/client-common";
import { textInputLocalization } from "../../../utils/text.input.localization";
import { DocumentService } from "@hoylu/web-io-document";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
  transferServiceUrl: transferService(state),
  documentMetadataUrl: documentMetadata(state),
  authHeader: authorizationHeader(state),
  token: userToken(state),
  fetchApi: window.fetch,
  saveFile: downloadBlob,
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

export type DownloadDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const DownloadDialog: React.FC<DownloadDialogProps> = ({
  cancelDashboardOption,
  selectedWorkspaceDetails,
  transferServiceUrl,
  documentMetadataUrl,
  authHeader,
  token,
  fetchApi,
  saveFile,
}) => {
  const strings = Localized.object("DOWNLOAD_DIALOG");
  const errors = Localized.object("ERROR");

  const isPasswordRequired = selectedWorkspaceDetails?.hasPassword;

  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(
    isPasswordRequired
  );
  const [password, setPassword] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [message, setMessage] = useState("");
  const [timestamp, setTimestamp] = useState<string | undefined>();
  const abortControllerRef = useRef<AbortController | null>(null);
  const isActive = useRef(true);
  useEffect(() => {
    return () => {
      isActive.current = false;
      abortControllerRef.current?.abort();
    };
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const service = new DocumentService({
      server: documentMetadataUrl,
      credentialProvider: () => ({ token }),
    });
    try {
      await service.connect(selectedWorkspaceDetails!.workspaceId, password);
      setDownloadButtonDisabled(false);
    } catch (error) {
      setIsPasswordInvalid(true);
      console.warn(error);
    }
  };

  // future improvements
  // https://stackoverflow.com/a/72914817
  //
  // use https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker
  // and https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream
  // or
  // https://github.com/jimmywarting/native-file-system-adapter

  // possible workaround https://stackoverflow.com/a/72576242
  const download = async () => {
    abortControllerRef.current = new AbortController();
    setDownloadButtonDisabled(true);
    setMessage(strings.DOWNLOADING);
    let url = `${transferServiceUrl}/Download/${
      selectedWorkspaceDetails!.workspaceId
    }`;
    if (timestamp) {
      const dateTime = new Date(timestamp);
      url += `?timestamp=${dateTime.toISOString()}`;
    }
    try {
      const response = await fetchApi(url, {
        method: "GET",
        mode: "cors",
        headers: authHeader,
        signal: abortControllerRef.current.signal,
      });
      if (response.ok) {
        const filename =
          response.headers
            .get("content-disposition")
            ?.split(";")
            .find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            .trim()
            .replace(/^"(.*)"$/, "$1") ||
          `${selectedWorkspaceDetails!.workspaceId}.zip`;
        const blob = await response.blob();
        saveFile(blob, filename);
        cancelDashboardOption();
      } else {
        if (isActive.current) {
          setMessage(strings.DOWNLOAD_FAILED);
          setDownloadButtonDisabled(false);
        }
        abortControllerRef.current = null;
        console.warn(response);
      }
    } catch (error) {
      if (isActive.current) {
        setMessage(strings.DOWNLOAD_FAILED);
        setDownloadButtonDisabled(false);
      }
      abortControllerRef.current = null;
      console.warn(error);
    }
  };

  if (!selectedWorkspaceDetails) return null;
  return (
    <Modal handleOutsideClick={cancelDashboardOption}>
      <>
        <h1 className={Styles.header}>{strings.DOWNLOAD}</h1>

        {isPasswordRequired && (
          <form
            className={Styles.passwordPrompt}
            role="form"
            onSubmit={onSubmit}
          >
            <TextInput
              label={strings.PASSWORD}
              type={"password"}
              useTogglePassword={true}
              onChange={(value) => {
                setPassword(value);
                setIsPasswordInvalid(false);
              }}
              name={"password"}
              isFocus={true}
              error={
                isPasswordInvalid
                  ? errors.INCORRECT_WORKSPACE_PASSWORD
                  : undefined
              }
              localizationDict={textInputLocalization()}
            />
          </form>
        )}
        {message}
        <TextInput
          type="datetime-local"
          label={strings.TIMESTAMP_LABEL}
          toolTip={strings.TIMESTAMP_TOOLTIP}
          onChange={(date) => setTimestamp(date)}
        />
        <footer className={Styles.footer}>
          <Button
            type="negative"
            onClick={cancelDashboardOption}
            toolTip={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type="primary"
            onClick={download}
            disabled={downloadButtonDisabled}
            toolTip={strings.DOWNLOAD}
          >
            {strings.DOWNLOAD}
          </Button>
        </footer>
      </>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDialog);
