import { useCallback, useContext } from "react";
import { UserSettingsContext } from "@hoylu/user-settings";
import { Localized } from "../../strings";

/** Get a localization function. To be used in React Functional Components */
export function useI18n(prefix: string = "") {
  const context = useContext(UserSettingsContext);

  return useCallback(
    (addr: string, ...parameters: any) =>
      Localized.string(prefix + addr, ...parameters),
    [context.userSettings?.currentLanguage, Localized.locales]
  );
}
