import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateDashboardOption,
  createWorkspaceFromTemplateId,
} from "../state/workspaces/workspaces.actions";
import { moduleDefaultImage } from "../utils/defaultImages";
import { UseCaseCard } from "./cards/UseCaseCard";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { Localized } from "../strings";
import { fetchTemplates } from "../state/templates/templates.actions";
import { templatesSelectors } from "../state/templates";
import { defaultModule } from "../utils/defaultModule";
import { QUICK_ACCESS_CATEGORY_ID } from "../services/templates/types";
import { TemplateMetadata } from "../state/templates/types";
import { Button } from "@hoylu/client-common";
import SliderLayout from "./layouts/SliderLayout";
import Styles from "./CreateWorkspaceSection.module.css";
import { getUserName } from "../state/user/user.selector";
import { useI18n } from "../utils/hooks/use.i18n";

export const mapName = (templateName: string) => {
  const strings = Localized.object("CREATE_WORKSPACES_SECTION").MODULES;

  switch (templateName) {
    case defaultModule:
      return strings.BLANK_WORKSPACE;
    case "workspace-ideas":
      return strings.WORKSPACE_IDEAS;
    case "pullplanningv4":
      return strings.PULL_PLANNING;
    case "agile-planning":
      return strings.AGILE_PLANNING;
    case "simple-drawing":
      return strings.SIMPLE_DRAWING;
    case "value-proposition":
      return strings.VALUE_PROPOSITION_CANVAS;
    case "supply-chain":
      return strings.SUPPLY_CHAIN;
    case "pi-planning":
      return strings.PI_PLANNING;
    case "kanban":
      return strings.KANBAN;
    case "weekly-planner":
      return strings.WEEKLY_PLANNER;
    case "retrospective":
      return strings.RETROSPECTIVE;
    case "business-model":
      return strings.BUSINESS_MODEL_CANVAS;
    case "swot-analysis":
      return strings.SWOT_ANALYSIS;
    default:
      return templateName;
  }
};

export const CreateWorkspaceSection: React.FC = () => {
  const dispatch = useDispatch();
  const userName = useSelector(getUserName);
  const t = useI18n("SECTIONS.");
  const tcw = useI18n("CREATE_WORKSPACES_SECTION.");

  const blankWorkspaceTemplate = {
    templateId: "",
    workspaceId: "",
    name: defaultModule,
  };

  const quickAccessTemplates = useSelector(
    templatesSelectors.quickAccessTemplates
  );
  const allDashboardTemplates: TemplateMetadata[] = [
    blankWorkspaceTemplate,
    ...quickAccessTemplates,
  ];

  const seeMoreBtnStyles = {
    fontWeight: "700",
    backgroundColor: "transparent",
    color: "var(--global--accent-deep--Color)",
    width: "fit-content",
    padding: 0,
    margin: 0,
  };

  useEffect(() => {
    dispatch(fetchTemplates.request({ categoryId: QUICK_ACCESS_CATEGORY_ID }));
  }, [dispatch]);

  const createFromTemplate = (templateId: string) => {
    dispatch(createWorkspaceFromTemplateId.request({ templateId }));
  };

  const createUseCaseWorkspace = (useCaseName: string) => {
    dispatch(
      activateDashboardOption({
        optionType: DashboardOption.CREATE,
        useCaseName,
      })
    );
  };

  const openAssetPanel = () => {
    dispatch(
      activateDashboardOption({
        optionType: DashboardOption.ASSET_PANEL,
      })
    );
  };

  return (
    <>
      <div className={Styles.createWorkspaceSection}>
        <div className={Styles.greetingsContainer}>
          <span className={Styles.greetings}>
            {t("START_FROM_TEMPLATE.TITLE", userName)}
          </span>
          <Button additionalStyles={seeMoreBtnStyles} onClick={openAssetPanel}>
            {t("START_FROM_TEMPLATE.BUTTON")} &gt;
          </Button>
        </div>
        <SliderLayout>
          {allDashboardTemplates.map((t, i) => (
            <UseCaseCard
              key={`${t.name}-${i}`}
              imageURL={moduleDefaultImage(t.name)}
              useCaseName={mapName(t.name)}
              tooltip={tcw("CREATE") + " "}
              onClick={() =>
                t.templateId && t.name != defaultModule
                  ? createFromTemplate(t.templateId)
                  : createUseCaseWorkspace(defaultModule)
              }
              workspaceId={t.workspaceId}
              enabled={true}
              isDefaultModule={t.name === defaultModule}
            />
          ))}
        </SliderLayout>
      </div>
    </>
  );
};
