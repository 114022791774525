import { MyWorkspaceMetadataV3 } from "../workspaces/api/workspaces.v3.types";
import { WorkspaceDetails } from "../../state/workspaces/types";
import { sendMessageToWorkspace } from "../../post-message-dispatch";

export const focusWorkspace = (id: string) => {
  const iframe = document.getElementById(id) as HTMLIFrameElement;
  const iWindow = iframe?.contentWindow;
  iWindow?.focus();
};

export const updateDocumentTitle = (title: string) => {
  document.title = title;

  if (document.querySelector('meta[property="og:title"]')) {
    document
      .querySelector('meta[property="og:title"]')!
      .setAttribute("content", title);
  }

  if (document.querySelector('meta[property="og:url"]')) {
    document
      .querySelector('meta[property="og:url"]')!
      .setAttribute("content", document.location.href);
  }
};

export const getDocumentTitle = () => {
  return document.title;
};

export const informWorkspaceIfChangedToTemplate = (
  response: MyWorkspaceMetadataV3,
  workspaces: WorkspaceDetails[]
) => {
  const oldStateOfWorkspace = workspaces.find(
    (workspace) => workspace.workspaceId === response.workspaceId
  );

  if (oldStateOfWorkspace && !oldStateOfWorkspace.templateId) {
    // workspace has just been changed into template,
    // we need to inform workspace about it to display template related UI settings
    sendMessageToWorkspace(response.workspaceId, {
      action: "WORKSPACE_DETAILS_UPDATED",
      details: {
        workspaceName: response.workspaceName,
        templateId: response.templateId,
      },
    });
  }
};
