import { German } from "../_localized/de";
import { English } from "../_localized/en";

// Update allLocalizations when adding new localizations
export const allLocalizations: { [key: string]: any } = {
  en: English,
  de: German,
};

export class Localized {
  static providers: any[] = [];
  static locales: string[] = [];

  static pushLocale(locale: string, provider: any) {
    // locale = localization file name (en.ts) vs provider = language name (English)
    this.locales = this.locales.filter((item) => item !== locale);
    this.providers = this.providers.filter((item) => item !== provider);
    Localized.providers.unshift(provider);
    Localized.locales.unshift(locale);
  }

  static object(address: string): any {
    return this.get(address);
  }

  static get(address: string): any {
    for (let i = 0; i < Localized.providers.length; i++) {
      let value = resolveAddress(address, Localized.providers[i]);
      if (value !== undefined) {
        return value;
      }
    }
    return undefined;
  }

  static string(address: string, ...parameters: any): string {
    let value = this.get(address);
    if (value === undefined || typeof value !== "string") {
      return String(address);
    }

    // parameters substituted into string:
    //   '${0} == parameter 1', '${1} == parameter 2', etc
    if (parameters && parameters.length > 0) {
      let strings = value.split(/(\$\{\d+\})/);
      return strings
        .map((s: any) => {
          let str = s + "";
          let matches = str.match(/^\$\{(\d+)\}$/);
          if (matches) {
            let index = parseInt(matches[1]);
            return index < parameters.length ? parameters[index] : s;
          } else {
            return s;
          }
        })
        .join("");
    }

    return value;
  }

  static array<T>(address: string): Array<T> {
    let value = this.get(address);
    if (value === undefined || !Array.isArray(value)) {
      return [];
    }
    return value;
  }
}

function resolveAddress(address: string, obj: any): any | undefined {
  let keys = address.split(".");
  let current = obj;
  for (let i = 0; i < keys.length; i++) {
    if (current === undefined || typeof current !== "object") {
      return undefined;
    }
    current = current[keys[i]];
  }
  return current;
}
